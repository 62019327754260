/**
 * 生成的测评链接的类型
 */
export enum EAppraisalLinkType {
    yeastar = 'yeastar',
    milesight = 'milesight',
}
export const EntranceStatusOptions = [
    {
        label: '未答题',
        value: 0,
    },
    {
        label: '答题中',
        value: 1,
    },
    {
        label: '已答题',
        value: 2,
    },
    {
        label: '已超时',
        value: 3,
    },
];
export const ReportStatusOptions = [
    {
        label: '已生成',
        value: 1,
    },
    {
        label: '未生成',
        value: 0,
    },
];
export const StatusOptions = [
    {
        label: '未过期',
        value: 0,
    },
    {
        label: '已过期',
        value: 1,
    },
];
export const SurveyTypeOptions = [
    {
        label: '16PF',
        value: 0,
    },
    {
        label: 'MBTI',
        value: 1,
    },
    {
        label: '职场适应性',
        value: 2,
    },
];

/**
 * 右上角搜索输入框最大输入字符数 250 个
 */
export const SEARCH_LIMIT = 250;

/** 线上地址 */
export const REQUEST_BASE_URL = import.meta.env.PROD ? '' : 'http://italent-test.milesight.com';
