export default {
    'http.200': '(Code:200) 服务器成功返回请求的数据。',
    'http.200.creation_limit_exceed': '(Code:200) 添加超过限制。',
    'http.200.failure': '(Code:200) 接口请求处理失败。',
    'http.200.partial_success': '(Code:200) 请求接口部分数据处理成功。',
    'http.200.success': '(Code:200) 请求接口处理成功。',
    'http.201': '(Code:201) 添加或编辑数据成功。',
    'http.202': '(Code:202) 请求已经进入后台等待处理（异步任务）。',
    'http.202.async_processing': '(Code:202) 请求已经进入后台等待处理（异步任务）。',
    'http.204': '(Code:204) 删除数据成功。',
    'http.400': '(Code:400) 请求参数语法错误。',
    'http.400.parameter_syntax_error': '(Code:400) 请求参数语法错误。',
    'http.401': '(Code:401) 用户没有权限。',
    'http.401.token_expired': '(Code:401) access_token过期或者网页端session过期。',
    'http.403': '(Code:403)访问被禁止。',
    'http.403.forbidden_account': '(Code:403)帐号被禁用，无法访问。',
    'http.403.forbidden_default_password': '(Code:403)未修改默认密码，禁止访问。',
    'http.403.forbidden_gdpr': '(Code:403)未确认GDPR隐私协议，禁止访问。',
    'http.403.forbidden_ip': '(Code:403)ip禁止访问。',
    'http.403.forbidden_locked': '(Code:403)帐号被锁定，无法访问。',
    'http.403.forbidden_permission': '(Code:403)无访问权限。',
    'http.404': '(Code:404)访问不存在。',
    'http.404.api_version_not_support': '(Code:404)请求的接口版本过高。',
    'http.404.api_version_out_of_maintenance': '(Code:404)请求的接口版本已过期。',
    'http.404.data_no_found': '(Code:404)数据不存在。',
    'http.404.resource_no_found': '(Code:404)资源不存在。',
    'http.404.specific_resource_no_found': '(Code:404){0} 资源数据不存在。',
    'http.405': '(Code:405)请求方法不被允许。',
    'http.405.method_not_allowed': '(Code:405)请求方法错误。',
    'http.406': '(Code:406)请求格式不可得。',
    'http.410': '(Code:410)请求资源已被永久删除。',
    'http.413.import_too_large': '(Code:413)文件大小超过上限。',
    'http.422': '(Code:422)创建对象时验证错误。',
    'http.422.import_invalid_large': '(Code:422)无效文件。',
    'http.422.parameter_validation_failed': '(Code:422)参数验证错误。',
    'http.500': '(Code:500)服务器发生错误。',
    'http.500.internal_server_error': '(Code:500)服务器发生错误。',
    'http.502': '(Code:502)网关错误。',
    'http.502.gateway_error': '(Code:502)网关错误。',
    'http.503': '(Code:503)服务不可用。',
    'http.503.system_maintaining': '(Code:503)系统维护中。',
    'http.504': '(Code:504)访问超时。',
    'http.504.gateway_timeout': '(Code:504)访问超时。',
    'http.anomaly': '网络异常。',
    'http.anomaly_message': '网络异常，无法连接服务器。',
    'http.request_error': '请求错误。',
};
