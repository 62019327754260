import React from 'react';
import Dispatcher, { Namespaces } from './Dispatcher';
import Executor from './Executor';

/**
 * 创建一个全局的 Context
 */
export const Context = React.createContext<{ dispatcher: Dispatcher }>({
    dispatcher: {
        callbacks: {},
        data: {},
        update: () => {},
    },
});

/** 初始化一个 Dispatcher 实例 */
const dispatcher = new Dispatcher();
/**
 * 提供一个 Provider 组件，
 * 供子组件共享数据，
 * 它们可以通过 useContext 获取到全局的 dispatcher 实例
 */
function Provider(props: { models: Record<string, any>; children: React.ReactNode }) {
    return (
        <Context.Provider value={{ dispatcher }}>
            {Object.keys(props.models).map(namespace => {
                return (
                    <Executor
                        key={namespace}
                        hook={props.models[namespace].model}
                        namespace={namespace}
                        onUpdate={val => {
                            dispatcher.data[namespace as Namespaces] = val;
                            dispatcher.update(namespace as Namespaces);
                        }}
                    />
                );
            })}
            {props.children}
        </Context.Provider>
    );
}

export default Provider;
