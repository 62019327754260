export default {
    'http.200': '(Code:200) The server successfully returned the requested data.',
    'http.200.creation_limit_exceed': '(Code:200) Exceeded the maximum adding limit.',
    'http.200.failure': '(Code:200) Interface request processing failure.',
    'http.200.partial_success': '(Code:200) The data processing of the requested interface was partially successful.',
    'http.200.success': '(Code:200) The interface request was processed successfully.',
    'http.201': '(Code:201) Data added or edited successfully.',
    'http.202': '(Code:202) The request has entered the background waiting for processing (asynchronous task).',
    'http.202.async_processing':
        '(Code:202) The request has entered the background waiting for processing (asynchronous task).',
    'http.204': '(Code:204) Data deleted successfully.',
    'http.400': '(Code: 400) Requested parameter syntax error.',
    'http.400.parameter_syntax_error': '(Code: 400) Requested parameter syntax error.',
    'http.401': '(Code: 401) The user has no permission.',
    'http.401.token_expired': '(Code: 401) The access_token or web session has expired.',
    'http.403': '(Code: 403) Access Forbidden.',
    'http.403.forbidden_account': '(Code: 403) Access Forbidden. The account is blocked.',
    'http.403.forbidden_default_password':
        "(Code: 403) Access Forbidden. The default password hasn't been changed yet.",
    'http.403.forbidden_gdpr': '(Code: 403) Access Forbidden. GDPR privacy agreement not acknowledged.',
    'http.403.forbidden_ip': '(Code: 403) Access Forbidden. The IP address is blocked.',
    'http.403.forbidden_locked': '(Code: 403) Access Forbidden. The account is locked.',
    'http.403.forbidden_permission': '(Code: 403) Access Forbidden. No Permission.',
    'http.404': '(Code: 404) Not Found.',
    'http.404.api_version_not_support':
        '(Code: 404) The version of the requested interface is higher than the supported.',
    'http.404.api_version_out_of_maintenance':
        '(Code: 404) The version of the requested interface is out of maintenance.',
    'http.404.data_no_found': '(Code: 404) Data Not Found.',
    'http.404.resource_no_found': '(Code: 404) Resource Not Found.',
    'http.404.specific_resource_no_found': '(Code: 404) Resource/Data Not Found.',
    'http.405': '(Code: 405) Method Not Allowed.',
    'http.405.method_not_allowed': '(Code: 405) Method Not Allowed.',
    'http.406': '(Code: 406) The request is not acceptable.',
    'http.410': '(Code: 410) The requested resource has been deleted.',
    'http.413.import_too_large': '(Code: 413) The file size has exceeded the max limit.',
    'http.422': '(Code: 422) Validation error when creating an object.',
    'http.422.import_invalid_large': '(Code: 422) Invalid File.',
    'http.422.parameter_validation_failed': '(Code: 422) Parameter Validation Error.',
    'http.500': '(Code: 500) Internal Server Error.',
    'http.500.internal_server_error': '(Code: 500) Internal Server Error.',
    'http.502': '(Code: 502) Bad Gateway.',
    'http.502.gateway_error': '(Code: 502) Bad Gateway.',
    'http.503': '(Code: 503) Service Unavailable.',
    'http.503.system_maintaining': '(Code: 503) System Maintenance in Progress.',
    'http.504': '(Code: 504) Gateway Timeout.',
    'http.504.gateway_timeout': '(Code: 504) Gateway Timeout.',
    'http.anomaly': 'Abnormal Network Connection.',
    'http.anomaly_message': 'Abnormal Network Connection. Cannot Connect to the Server.',
    'http.request_error': 'Request Error.',
};
