import client from "./client";
import global from "./global";
import other from "./other";

/**
 * 注意：models 的 key 一定要和 namespace 值相同，否则会无法正确找到 model
 */
export const models = {
  global: { namespace: "global", model: global },
  other: { namespace: "other", model: other },
  client: { namespace: "client", model: client },
} as const;
