// 全局共享数据示例2
import { useState } from 'react';

const useVisible = () => {
    const [visible, setVisible] = useState<boolean>(true);

    return {
        visible,
        setVisible,
    };
};

export default useVisible;
