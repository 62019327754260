// 全局共享数据示例
import { useState } from "react";

const useClient = () => {
  const [detailInfo, setDetailInfo] = useState<any>({});

  return {
    detailInfo,
    setDetailInfo,
  };
};

export default useClient;
