import { getLocale } from '@components/Locale';

/**
 * 获取语言，用于处理国际化接口请求
 * @returns string
 */
export default function getAcceptLanguage(): string {
    let lang: string = 'en-US,en;q=0.8';
    const locale = getLocale();
    if (locale) {
        const formatLocale = locale.split('-');
        lang = `${locale},${formatLocale[0]};q=0.8,en;q=0.1`;
    }

    return lang;
}
