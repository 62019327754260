import isEqual from 'fast-deep-equal';
import { useContext, useEffect, useRef, useState } from 'react';
import Dispatcher, { Models, Namespaces } from './Dispatcher';
import { Context } from './Provider';

type Model<N> = GetModelByNamespace<Models, N>;
type Selector<N, S> = (model: Model<N>) => S;

type SelectedModel<N, T> = T extends (...args: any) => any ? ReturnType<NonNullable<T>> : Model<N>;

export function useModel<N extends Namespaces>(namespace: N): Model<N>;

export function useModel<N extends Namespaces, S>(
    namespace: N,
    selector: Selector<N, S>,
): SelectedModel<N, typeof selector>;

export function useModel<N extends Namespaces, S>(
    namespace: N,
    selector?: Selector<N, S>,
): SelectedModel<N, typeof selector> {
    // 使用 ./model 中创建的 Context，获取到 dispatcher 实例
    const { dispatcher } = useContext<{ dispatcher: Dispatcher }>(Context);
    const selectorRef = useRef(selector);
    selectorRef.current = selector;
    // 通过 dispatcher 初始化当前的 namespace 的 model 值
    const [state, setState] = useState(() =>
        selectorRef.current
            ? selectorRef.current(dispatcher?.data?.[namespace] as Model<N>)
            : dispatcher?.data?.[namespace],
    );
    const stateRef = useRef<any>(state);
    stateRef.current = state;

    // 判断当前组件是否挂载，保证只使用一次
    const isMount = useRef(false);
    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
        };
    }, []);

    useEffect(() => {
        const handler = (data: any) => {
            // 确保值调用一次
            if (!isMount.current) {
                // 如果 handler 执行过程中，组件被卸载了，则强制更新全局 data
                // TODO: 需要加个 example 测试
                setTimeout(() => {
                    dispatcher.data[namespace] = data;
                    dispatcher.update(namespace);
                });
            } else {
                const currentState = selectorRef.current ? selectorRef.current(data) : data;
                const previousState = stateRef.current;
                if (!isEqual(currentState, previousState)) {
                    // 避免 currentState 拿到的数据是老的，从而导致 isEqual 比对逻辑有问题
                    stateRef.current = currentState;
                    setState(currentState);
                }
            }
        };

        // rawModels 是 umi 动态生成的文件，导致前面 callback[namespace] 的类型无法推导出来，所以用 as any 来忽略掉
        dispatcher.callbacks[namespace] ||= new Set() as any;
        dispatcher.callbacks[namespace]?.add(handler);
        dispatcher.update(namespace);

        return () => {
            dispatcher.callbacks[namespace]?.delete(handler);
        };
    }, [namespace]);

    return (state ?? {}) as SelectedModel<N, typeof selector>;
}

/**
 * 通过 namespace 获取到 model 的数据结构
 */
type GetModelByNamespace<M, N> = {
    [K in keyof M]: M[K] extends { namespace: string; model: unknown }
        ? M[K]['namespace'] extends N
            ? M[K]['model'] extends (...args: any) => any
                ? ReturnType<M[K]['model']>
                : never
            : never
        : never;
}[keyof M];
