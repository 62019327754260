import React from "react";

import moment from "moment";
// import 'moment/locale/de';
// import 'moment/locale/en-au';
import "moment/locale/es";
// import 'moment/locale/fr';
// import 'moment/locale/it';
// import 'moment/locale/pt-br';
// import 'moment/locale/tr';
import "moment/locale/zh-cn";
import { ConfigProvider, Empty } from "ysd-pp";

import { LANG_CHANGE_EVENT, event } from "./constant";
import {
    RawIntlProvider,
    getDirection,
    getIntl,
    getLocale,
    localeInfo,
} from "./localeExports";

const useIsomorphicLayoutEffect =
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
        ? React.useLayoutEffect
        : React.useEffect;

export const LocaleContainer = (props: any) => {
    const [locale, setLocale] = React.useState(() => getLocale());
    const [intl, setContainerIntl] = React.useState(() =>
        getIntl(locale, true)
    );

    const handleLangChange = (_locale: string) => {
        if (moment?.locale) {
            moment.locale(localeInfo[_locale]?.momentLocale || "en");
        }
        setLocale(_locale);
        setContainerIntl(getIntl(_locale));
    };

    useIsomorphicLayoutEffect(() => {
        event.on(LANG_CHANGE_EVENT, handleLangChange);
        return () => {
            event.off(LANG_CHANGE_EVENT, handleLangChange);
        };
    }, []);

    const defaultYsdPPLocale = {};
    const direction = getDirection();

    return (
        <ConfigProvider
            direction={direction}
            locale={localeInfo[locale]?.ysdPP || defaultYsdPPLocale}
            renderEmpty={() => <Empty />}
        >
            <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>
        </ConfigProvider>
    );
};
