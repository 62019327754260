import { clearToken } from './getToken';

export const getPageQuery = () => JSON.parse(window.location.href.split('?')[1] || '{}');
export const generateRedirectUrl = (): any => window.location.href.replace(/redirect=.*$/, '').replace(/\?$/, '');
/**
 * 下载方法
 * @param fileObj
 * @param filename filename = 'xxx.png'
 */
export const download = async (fileObj: any, filename = 'xxx.png') => {
    if (fileObj) {
        let a: any = window.document.createElement('a');
        // 非 Edge 的浏览器自动添加打开新窗口
        if (!/edge?\/[\d.]+/.test(navigator.userAgent.toLowerCase())) {
            a.setAttribute('target', '_blank');
        }
        if (typeof fileObj === 'string') {
            a.href = fileObj;
            a.download = filename;
            a.click();
        } else {
            const blobUrl = window.URL.createObjectURL(fileObj);
            a.href = blobUrl;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            a = null;
        }
    }
};

/**
 * 模拟点击a标签跳转新窗口，替代window.open，防止被拦截
 */
export const openNewWindow = (url: string) => {
    // setTimeout可以跳过chrome，fire fox对于跨域链接的审查，不需要浏览器进行其他的设置
    setTimeout(() => {
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('rel', 'noreferrer');
        a.setAttribute('target', '_blank');
        a.click();
    }, 0);
};
/**
 * 判断类型
 * @param type
 */
export function isType(
    type:
        | 'String'
        | 'Number'
        | 'Boolean'
        | 'Array'
        | 'Object'
        | 'Function'
        | 'Null'
        | 'Undefined'
        | 'Symbol'
        | 'BigInt'
        | 'Date'
        | 'RegExp'
        | 'Promise',
) {
    return function (obj) {
        return Object.prototype.toString.call(obj) === '[object ' + type + ']';
    };
}
/**
 * 判断是否是数组，是的话返回数组，否则返回[]
 * @param arr
 * @returns
 */
export function returnArray(arr: any) {
    return isType('Array')(arr) ? arr : [];
}

/**
 * 判断是否是对象，是的话返回对象，否则返回{}
 * @param obj
 * @returns
 */
export function returnObject(obj: any) {
    return isType('Object')(obj) ? obj : {};
}

/**
 * 响应状态 401，跳转到登录界面：
 * 只有当 api 请求返回 401 错误
 * 或者业务错误状态码返回 401
 * 就表示登录失效，需要自动跳转到登录页面
 */
export function redirectToLoginPage() {
    clearToken();

    const url = generateRedirectUrl();

    if (!/\/user\/login/.test(url)) {
        window.location.href = '/user/login';
    }
}

export function redirectToTargetPage(target: string = '../') {
    // 透传url参数
    const params: Record<string, any> = getPageQuery();
    const routeParams = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    window.location.href = `${target}${routeParams ? `?${routeParams}` : ''}`;
}
/**
 * 转换成数值，并且转化之前先如果是字符串先去掉左右空格
 * @param val
 * @param defaultValueWhenNaN 当转化为的数值是 NaN 时，可以使用指定的默认值
 * @returns
 */
export function toNumber(val, defaultValueWhenNaN?) {
    if (typeof val === 'number') return val;
    const n = parseFloat(`${val}`.trim());

    if (typeof defaultValueWhenNaN === 'undefined') {
        return n;
    }

    return isNaN(n) ? defaultValueWhenNaN : n;
}
/**
 * 这个方法与 前面的 toNumber 的功能是一样的，
 * 唯一的差异就是 defaultValueWhenNaN 指定为 undefined 的时候也可以返回 undefined
 * @param val
 * @param defaultValueWhenNaN
 * @returns
 */
export function toNumber2(val, defaultValueWhenNaN?) {
    const result = toNumber(val, defaultValueWhenNaN);

    return isNaN(result) ? defaultValueWhenNaN : result;
}

export const mockPwd = 't@_#PMsGCBaWA!%1';
