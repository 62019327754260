import { useEffect, useMemo, useRef } from 'react';

interface ExecutorProps {
    hook: () => any;
    onUpdate: (val: any) => void;
    namespace: string;
}

/**
 * 执行每一个 model 的
 */
function Executor(props: ExecutorProps) {
    const { hook, onUpdate, namespace } = props;

    const updateRef = useRef(onUpdate);
    const initialLoad = useRef(false);

    let data: any;
    try {
        // 执行对应 namespace 的 hooks 函数
        data = hook();
    } catch (e) {
        console.error(`@components/useModel: Invoking '${namespace || 'unknown'}' model failed:`, e);
    }

    // 首次执行时立刻返回初始值
    useMemo(() => {
        updateRef.current(data);
    }, []);

    // React 16.13 后 update 函数用 useEffect 包裹
    useEffect(() => {
        // 保证初始化之后一直更新数据，那么通过 onUpdate 获取到对应的 model 的最新数据
        if (initialLoad.current) {
            updateRef.current(data);
        } else {
            initialLoad.current = true;
        }
    });

    return null;
}

export default Executor;
