// import { getIntl } from '@components/Locale';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { stringify } from 'qs';
import { message } from 'ysd-pp';

import { useRequest as _useRequest } from 'ahooks';
import { redirectToLoginPage } from '..';
import { REQUEST_BASE_URL } from '../constants';
import { getToken } from '../getToken';
import cancelRequest, { cacheRequestCancelToken } from './cancelRequest';
import errorHandler from './errorHandler';
import getAcceptLanguage from './getAcceptLanguage';

export interface RequestConfig extends AxiosRequestConfig {
    /**
     * 是否允许频繁的重复发送请求，默认是不允许
     */
    allowRepeat?: boolean;
    /**
     * 业务错误码中的国际化key前缀
     */
    codePrefix?: string;
    /**
     * api 响应时携带错误码的情况下，是否展示弹窗提醒
     */
    showCodeMessage?: boolean;
}

const axiosInstance = axios.create({
    /**
     * `timeout` 指定请求超时的毫秒数。
     * 如果请求时间超过 `timeout` 的值，则请求会被中断
     * 默认值是 `0` (永不超时)
     */
    timeout: 1000 * 60, // 在创建 axios 实例的时候设置 timeout 才能生效
});

/**
 * request拦截器
 * 处理：请求头携带 token
 * 处理：请求加密数据，默认都加密，
 * 处理：携带国际化语言请求
 * 处理：重复请求，默认取消重复请求
 */
axiosInstance.interceptors.request.use(
    config => {
        const _config: RequestConfig = {
            /**
             * 设置统一的请求前缀
             */
            baseURL: REQUEST_BASE_URL,

            /**
             * `timeout` 指定请求超时的毫秒数。
             * 如果请求时间超过 `timeout` 的值，则请求会被中断
             * 默认值是 `0` (永不超时)
             */
            // timeout: 1000 * 60,

            /**
             * `withCredentials` 表示跨域请求时是否需要使用凭证
             */
            withCredentials: false,

            paramsSerializer: search => stringify(search, { arrayFormat: 'repeat' }),

            ...config,

            headers: {
                'Content-Type': 'application/json',

                /**
                 * 请求语言
                 */
                'Accept-Language': getAcceptLanguage(),
                /**
                 * 传给后端的：格林威治时间和本地时间之间的时差分钟数
                 */
                TimezoneOffset: new Date().getTimezoneOffset(),

                ...config.headers,
            },
        };

        /**
         * 请求携带 token
         * TODO: 当前先采用 localStorage 缓存 token，考虑安全性可以替换成 cookie 缓存，并且提供过期时间
         */
        const token = getToken();
        if (token && _config.headers) {
            _config.headers.Authorization = `Bearer ${token}`;
        }

        /**
         * 处理：重复请求
         * 默认情况下是不允许重复请求的
         * 如果想要支持重复请求，可以传入 { allowRequest: true }
         */
        cancelRequest(_config);
        if (_config.allowRepeat !== true) {
            cacheRequestCancelToken(_config);
        }

        // 类型内部发生丢失，处理成 any，否则会报错
        return _config as any;
    },
    error => {
        if (error.config) {
            cancelRequest(error.config);
        }
        return errorHandler(error);
    },
);

/**
 * response 拦截器
 * 处理：token 更新
 * 处理：数据解密
 * 处理：重复请求
 */
axiosInstance.interceptors.response.use(
    response => {
        if (
            response.status === 401 ||
            response.data?.code === 401 ||
            response.status === 403 ||
            response.data?.code === 403
        ) {
            // TODO: 需要做实际的业务处理
            redirectToLoginPage();
            message.error('无权限');
            return;
        }

        cancelRequest(response.config);

        return {
            ...response.data,
            codePrefix: response?.config?.headers?.codePrefix,
        };
    },
    error => {
        if (
            error?.response?.status === 401 ||
            error?.response?.data?.code === 403 ||
            error?.response?.status === 401 ||
            error?.response?.data?.code === 403
        ) {
            // TODO: 需要做实际的业务处理
            redirectToLoginPage();
            message.error('无权限');
            return;
        }

        /** 针对版本检测处理不进行报错 */
        if (/\/versions\.json/.test(error?.config?.url)) {
            return;
        }

        if (error.config) {
            cancelRequest(error.config);
        }

        return errorHandler(error);
    },
);

type UseRequest = typeof _useRequest;
export const useRequest: UseRequest = function (service, options?, plugins?) {
    return _useRequest(
        service,
        {
            manual: true, // 把默认自动执行请求变更为默认手动通过 run 或者 runAsync 触发请求
            ...options,
        },
        plugins,
    );
};

/**
 * 发送 API 请求
 * 这个方法是整个项目中所有 api 请求的统一方法。
 *
 * @param url string
 * @param configs RequestConfig
 * @returns Promise<IResponse<T>>
 */
export interface IResponse<T> {
    type: number;
    code: number | string;
    message: string;
    requiredId?: string;
    data: T;
    status?: number;
}
export default function request<T = any>(url: string, configs: RequestConfig = {}) {
    // const { codePrefix, showCodeMessage = true } = configs;

    return axiosInstance
        .request<RequestConfig, IResponse<T>>({
            ...configs,
            url,
        })
        .then(res => {
            // 统一处理业务的错误编码
            // if (res?.code !== 200 && showCodeMessage) {
            //     const intl = getIntl();

            //     const key = `${codePrefix ? codePrefix + '.' : ''}${res?.code}`;
            //     if (typeof intl.messages[key] !== 'undefined') {
            //         message.error(intl.formatMessage({ id: key }));
            //     }
            // }

            return res;
        });
}
