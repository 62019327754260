import type { models as rawModels } from '@models/index';

export type Models = typeof rawModels;

/**
 * 把 model 对象所有属性的 namespace 字段的值作为可选类型值，
 *
 * 举例：
 * model = {
 *    model_1: {
 *        namespace: 'model_A',
 *    },
 *    model_2: {
 *        namespace: 'model_B',
 *    },
 *    model_3: {
 *        namespace2: 'model_C'
 *    }
 * }
 *
 * type Namespaces = GetNamespaces<typeof model> => 'model_A' | 'model_B'
 */
type GetNamespaces<M> = {
    [K in keyof M]: M[K] extends { namespace: string } ? M[K]['namespace'] : never;
}[keyof M];

export type Namespaces = GetNamespaces<Models>;

class Dispatcher {
    /**
     * 由于同一个 namespace 可以在多个地方进行数据共享，
     * 所以在数据更新时，需要做所有依赖的同步更新，这里存放依赖更新回调
     */
    callbacks: Partial<Record<Namespaces, Set<(...arg: any) => any>>> = {};
    /** 一个 namespace 下的 model 值 */
    data: Partial<Record<Namespaces, unknown>> = {};
    /** 一个 namespace 的数据更新时，需要同步更新所有的依赖 */
    update = (namespace: Namespaces) => {
        if (this.callbacks[namespace]) {
            this.callbacks[namespace]!.forEach(cb => {
                try {
                    const data = this.data[namespace];
                    cb(data);
                } catch (e) {
                    cb(undefined);
                }
            });
        }
    };
}

export default Dispatcher;
