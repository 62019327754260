import { getIntl } from "@components/Locale";
import axios from "axios";
import { notification } from "ysd-pp";

/**
 * 根据 status + code 获取错误信息的 key
 *
 * @param status number|string
 * @param code string
 * @returns
 */
function getKey(status: number | string, code?: string) {
    const baseKey = `http.${status}`;
    const key = `${baseKey}${code ? `.${code}` : ""}`;

    return key;
}

/**
 * 异常处理
 */
const errorHandler = (error: any) => {
    if (axios.isCancel(error))
        return console.warn("Repeat Request: " + error.message);

    const intl = getIntl();
    const { response } = error;
    if (response && response.status) {
        notification.error({
            description: intl.formatMessage({
                id: getKey(response.status, response.data.code),
            }),
            message: intl.formatMessage({ id: getKey("request_error") }),
        });
    } else if (error.code === "ERR_NETWORK" || !response) {
        notification.error({
            description: intl.formatMessage({ id: getKey("anomaly_message") }),
            message: intl.formatMessage({ id: getKey("anomaly") }),
        });
    }

    throw error;
    // return error;
};

export default errorHandler;
