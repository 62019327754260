import type { IntlShape, MessageDescriptor } from "react-intl";
import { createIntl } from "react-intl";

import lang_enUS from "@/locales/en-US";
import lang_zhCN from "@/locales/zh-CN";
import warning from "rc-util/es/warning";
import enUS from "ysd-pp/es/locale/en_US";
import zhCN from "ysd-pp/es/locale/zh_CN";

import { LANG_CHANGE_EVENT, event } from "./constant";

export {
    FormattedDate,
    FormattedDateParts,
    FormattedDisplayName,
    // FormattedHTMLMessage,
    FormattedList,
    FormattedMessage,
    FormattedNumber,
    FormattedNumberParts,
    FormattedPlural,
    FormattedRelativeTime,
    FormattedTime,
    FormattedTimeParts,
    IntlContext,
    IntlProvider,
    RawIntlProvider,
    createIntlCache,
    defineMessages,
    injectIntl,
    useIntl,
} from "react-intl";
export { createIntl };

let g_intl: IntlShape;

const useLocalStorage = true;

export const localeInfo: Record<string, any> = {
    "en-US": {
        messages: {
            ...lang_enUS,
        },
        locale: "en-US",
        ysdPP: {
            ...enUS,
        },
        momentLocale: "",
    },
    "zh-CN": {
        messages: {
            ...lang_zhCN,
        },
        locale: "zh-CN",
        ysdPP: {
            ...zhCN,
        },
        momentLocale: "zh-cn",
    },
};

/**
 * 缩写转全称，解决默认选中问题
 */
const localeInfoAbbr2full: Record<string, string> = {
    en: "en-US",
    zh: "zh-CN",
};

/**
 * 增加一个新的国际化语言
 * @param name 语言的 key
 * @param messages 对应的枚举对象
 * @param extraLocale momentLocale, ysd-pp 国际化
 */
export function addLocale(
    name: string,
    messages: Record<string, any>,
    extraLocales?: {
        momentLocale: string;
        ysdPP: string;
    }
) {
    if (!name) {
        return;
    }
    // 可以合并
    const mergeMessages = localeInfo[name]?.messages
        ? Object.assign({}, localeInfo[name].messages, messages)
        : messages;

    const { momentLocale, ysdPP } = extraLocales || {};
    const locale = name.split("-")?.join("-");
    localeInfo[name] = {
        messages: mergeMessages,
        locale,
        momentLocale: momentLocale,
        ysdPP,
    };
    // 如果这是的 name 和当前的locale 相同需要重新设置一下，不然更新不了
    if (locale === getLocale()) {
        event.emit(LANG_CHANGE_EVENT, locale);
    }
}

/**
 * 获取当前的 intl 对象，可以在 node 中使用
 * @param locale 需要切换的语言类型
 * @param changeIntl 是否不使用 g_intl
 * @returns IntlShape
 */
export function getIntl(locale?: string, changeIntl?: boolean) {
    // 如果全局的 g_intl 存在，且不是 setIntl 调用
    if (g_intl && !changeIntl && !locale) {
        return g_intl;
    }
    // 如果存在于 localeInfo 中
    if (locale && localeInfo[locale]) {
        return createIntl(localeInfo[locale]);
    }
    // 不存在需要一个报错提醒
    warning(
        !locale || !!localeInfo[locale],
        `The current popular language does not exist, please check the locales folder!`
    );

    // 使用 en-US
    if (localeInfo["en-US"]) return createIntl(localeInfo["en-US"]);

    // 如果还没有，返回一个空的
    return createIntl({
        locale: "en-US",
        messages: {},
    });
}

/**
 * 切换全局的 intl 的设置
 * @param locale 语言的key
 */
export function setIntl(locale: string) {
    g_intl = getIntl(locale, true);
}

/**
 * 获取当前选择的语言
 * @returns string
 */
export function getLocale() {
    // please clear localStorage if you change the baseSeparator config
    // because changing will break the app
    const lang =
        navigator.cookieEnabled &&
        typeof localStorage !== "undefined" &&
        useLocalStorage
            ? window.localStorage.getItem("pp_locale")
            : "";
    // support baseNavigator, default true
    const isNavigatorLanguageValid =
        typeof navigator !== "undefined" &&
        typeof navigator.language === "string";
    const browserLang = isNavigatorLanguageValid
        ? navigator.language.split("-").join("-")
        : "";

    const _lang = lang || browserLang;
    const _langPrefix = _lang.split("-")[0];
    return localeInfo[_lang]
        ? _lang
        : localeInfoAbbr2full[_langPrefix]
        ? localeInfoAbbr2full[_langPrefix]
        : "en-US";
}

/**
 * 获取当前选择的方向
 * @returns string
 */
export function getDirection() {
    const lang = getLocale();
    // array with all prefixs for rtl langueges ex: ar-EG , he-IL
    const rtlLangs = ["he", "ar", "fa", "ku"];
    const direction = rtlLangs.filter((lng) => lang.startsWith(lng)).length
        ? "rtl"
        : "ltr";
    return direction;
}

/**
 * 切换语言
 * @param lang 语言的 key
 * @param realReload 是否刷新页面，默认刷新
 * @returns string
 */
export function setLocale(lang: string, realReload: boolean = true) {
    const updater = () => {
        if (getLocale() !== lang) {
            if (
                navigator.cookieEnabled &&
                typeof window.localStorage !== "undefined" &&
                useLocalStorage
            ) {
                window.localStorage.setItem("pp_locale", lang || "");
            }
            setIntl(lang);
            if (realReload) {
                window.location.reload();
            } else {
                event.emit(LANG_CHANGE_EVENT, lang);
                // chrome 不支持这个事件。所以人肉触发一下
                if (window.dispatchEvent) {
                    const _event = new Event("languagechange");
                    window.dispatchEvent(_event);
                }
            }
        }
    };

    updater();
}

let firstWaring = true;

/**
 * intl.formatMessage 的语法糖
 * @deprecated 使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl
 * @param descriptor { id : string, defaultMessage : string }
 * @param values { [key:string] : string }
 * @returns string
 */
export const formatMessage: IntlShape["formatMessage"] = (
    descriptor: MessageDescriptor,
    values: any
) => {
    if (firstWaring) {
        warning(
            false,
            `Using this API will cause automatic refresh when switching languages, please use useIntl or injectIntl.

使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl。

http://j.mp/37Fkd5Q
      `
        );
        firstWaring = false;
    }
    return g_intl.formatMessage(descriptor, values);
};

/**
 * 获取语言列表
 * @returns string[]
 */
export const getAllLocales = () =>
    Object.keys(localeInfo).filter((key) => ["en-US", "zh-CN"].includes(key));
