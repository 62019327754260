import requestErrorLocales from '@utils/request/locales/en-US';

export default {
    ...requestErrorLocales,

    'common.english': 'English',
    'common.chinese': '简体中文',
    'common.spanish': 'Español',
    'common.german': 'Deutsch',
    'common.french': 'Français',
    'common.italian': 'Italiano',
    'common.turkish': 'Türkçe ',

    'menu.home': 'Home',
    'menu.about': 'About',
    'menu.demo': 'Demo',
    'menu.register': 'Register',
    'menu.login': 'Login',
};
