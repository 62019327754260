import { Result } from "ysd-pp";

function Page404() {
    return (
        <Result
            title={404}
            subTitle={"Sorry, the page you visited does not exist."}
        />
    );
}

export default Page404;
